@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.goal-card-container {
  @include flexible(column, flex-start, center);
  padding-top: 12px;
  padding-bottom: 24px;
  border: 1px solid $grey10;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 24px;
  position: relative;

  .circle-checkbox-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .shop-image {
    margin-bottom: 4px;
    transition: transform 1.5s;
  }

  &:hover {
    border: 1px solid $grey25;

    .shop-image {
      transform: scale(1.1);
    }
  }

  .title {
    @include heading-4;
    margin-bottom: 8px;
  }

  .description {
    color: $grey50;
    @include body-small;
    text-align: center;
    max-width: 190px;
  }

  &.selected {
    border: 1.5px solid $indigo;
  }
}


