@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.action-header-container {
  @include flexible(row, flex-start, center);
  gap: 4px;
  width: 100%;
  padding: 12px 12px 16px;
  background: $white;
  border-bottom: 1px solid $lightBorder;

  svg {
    margin-top: 4px;
    @include size(18px);
  }

  .text-container {
    @include flexible(row, flex-start, center);
    @extend %body-small;
    flex: 1;

    .avatar-container {
      margin-right: 8px;
    }

    .name {
      @include button-medium-large;
      color: $grey50;
      white-space: pre-wrap;
    }

    .action-text {
      @include body-regular;
      color: $grey50;
      white-space: pre-wrap;
    }
  }
}