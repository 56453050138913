@import "src/styles/mixin";
@import "src/styles/colors";
@import "src/styles/typography";

.feed-bubbles-carousel-section {
  background: $white;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 0 12px;
  box-shadow: 0 1px 7px 0 rgba(40, 47, 45, 0.07);


  .sub-title {
    @include sub-heading;
    text-transform: uppercase;
    color: $grey50;
  }

  .multi-items-carousel-container {
    padding: 12px 0;
    border-radius: 4px;

    .slick-arrow {
      &.slick-next {
        right: 0;
        background: white;
        box-shadow: -20px 0 20px 13px white, 10px 0 10px 1px white;
      }

      &.slick-prev {
        left: 0;
        background: white;
        box-shadow: -20px 0 20px 13px white, 10px 0 10px 1px white;
        z-index: 1;
      }
    }

    .bubble-container {
      @include button-small;
      font-size: 14px;
      padding: 8px 12px;
      color: $grey50;
      border-radius: 32px;
      background: $white;
      width: fit-content;
      cursor: pointer;
      margin-right: 16px;

      &:hover {
        background: $grey5;
      }

      &.is-selected {
        background: $main10;
        color: $main;
      }
    }

    .slick-list {
      padding: 8px 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .feed-bubbles-carousel-section {
    margin-bottom: 8px;
  }
}
