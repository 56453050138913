@import "src/styles/mixin";
@import "src/styles/typography";

.like-button-container {
  @include size(32px);
  @include flexible(row, center, center);
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 1;
  background: $grey5;
  border-radius: 32px;
  cursor: pointer;

  svg {
    @include size(18px);

    &.filled-heart {
      path {
        fill: $main;
      }
    }
  }
}