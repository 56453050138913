@import "src/styles/mixin";
@import "src/styles/typography";

.mojo-skeleton {
  @include size(100%);
  @include flexible(row, space-between, flex-end);
  background: $grey85;
  padding: 28px;

  .title-skeleton {
    @include flexible(row, flex-start, center);

    .ant-skeleton-title {
      background: $grey50;
      width: 100px;
    }

    li {
      background: $grey50;
    }

    .ant-skeleton-avatar {
      background: $grey50;
    }

    .ant-skeleton-content {
      .ant-skeleton-paragraph {
        width: 177px;
        margin-top: 0;
      }
    }
  }

  .image-skeleton {
    @include size(89px, 99px);
    background: $grey50;
    border-radius: 8px;

    .ant-skeleton-header {
      padding: 0;

      .ant-skeleton-avatar {
        @include size(100%);
      }
    }
  }

  .text-skeleton {
    .ant-skeleton-paragraph {
      margin-bottom: 0;


      li {
        background: $grey50;
        height: 12px;
      }
    }
  }
}