@import "src/styles/mixin";
@import "src/styles/typography";

.facebook-sign-in-container {
  @include size($max, $fit);
  @include flexible(column, center, center);

  span {
    width: 100%;

    .facebook-button {
      @include flexible(row, center, center);
      @include size(100%, 48px);
      border: none;
      outline: none;
      background-color: #1877F2;
      cursor: pointer;
      color: $white;
      border-radius: 32px;
      font-family: "DM-Sans-Medium", serif;

      .facebook-icon {
        @include size(24px);
        margin-right: 8px;
      }
    }
  }

  &:hover {
    .facebook-button {

      background-color: $indigoDark;
    }
  }
}


