@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.skeleton_container {
  @include flexible(row, space-between, center);
  padding: 24px 16px;
  border-radius: 4px;
  gap: 12px;
  background-color: $grey5;
  width: 100%;

  .ant-skeleton-paragraph {
    margin: 0;
    width: 143px;
  }

  .ant-skeleton.ant-skeleton-with-avatar.ant-skeleton-active.ant-skeleton-round {
    width: fit-content;

    .ant-skeleton-header {
      padding-right: 0;
    }
  }
}