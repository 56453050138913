@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.page-container {
  .page {
    &.visions-page {
      @include flexible(column);
      width: 100%;
      padding-top: 24px;

      .visions-banner-container {
        @include flexible(column, center, center);
        position: relative;
        height: 217px;
        background-image: url("../../photos/visions/visions-banner-background.png");
        background-size: cover;
        color: $white;
        border-radius: 8px;

        .underline {
          @include size(268px, 56px);
          margin-top: -8px;
        }

        .total-credits-container {
          @include flexible(row, center, center);
          @include button-medium-large;
          gap: 8px;
          position: absolute;
          top: 24px;
          left: 24px;
          border-radius: 32px;
          background: rgba(25, 30, 28, 0.80);
          padding: 8px 12px;
          cursor: pointer;

          .divider {
            @include size(1px, 14px);
            background: rgba(255, 255, 255, 0.30);
          }

          svg {
            @include size(18px);

            path {
              fill: $white;
            }
          }
        }

        .title {
          @include heading-3;
          margin-bottom: 12px;
        }

        .description {
          @include button-medium-large;
        }
      }

      .generate-input-container {
        margin-top: -32px;
      }
    }
  }
}

.ant-notification-notice {
  &.generate-vision-error-message {
    width: 602px;

    .ant-notification-notice-content {

      .ant-notification-notice-with-icon {
        align-items: flex-start;

        .ant-notification-notice-message {
          padding: 0;
        }
      }
    }

    .ant-notification-notice-close {
      display: none;
    }
  }
}

@media only screen and (max-width: 900px) {
  .page-container {
    .page {
      &.visions-page {
        padding: 24px 8px;

        .requests-preview-container {
          flex-direction: column;
        }

        .visions-banner-container {
          .total-credits-container {
            top: 8px;
            left: 8px;
          }
        }

        .discover-carousel-container {
          background: unset;

          .multi-items-carousel-container {
            background: unset;
          }
        }
      }
    }
  }
}

