@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixin";

.basket-popover-container {
  .ant-popover-arrow {
    margin-right: -6px;
  }

  .basket-popover-inner-container {
    @include flexible(column);
    max-height: 462px;
    width: 350px;

    >.title {
      @include heading-5;
      color: $darkGrey;
      border-bottom: 1px solid $grey10;
      padding: 16px 16px 12px;
    }

    .price {
      @include heading-5;
      color: $darkGrey;
    }

    .items-container {
      overflow: auto;
      height: 100%;
      padding: 0 16px 0;

      .item-container {
        @include flexible(row, space-between, flex-start);
        gap: 32px;
        padding: 16px 0;
        border-bottom: 1px solid $grey10;
        border-top: 1px solid $grey10;
        color: $darkGrey;

        .left-side {
          display: flex;
          gap: 12px;

          .image-wrapper {
            @include size(80px);
            flex-shrink: 0;

            img {
              object-fit: cover;
              border-radius: 4px;
            }
          }

          .text-container {
            @include flexible(column, space-between);

            .item-title-container {
              @include flexible(column);

              .item-title {
                @include button-medium-large;
                @include ellipsisLines(1, 1.5rem);
              }

              .quantity {
                @include body-small;
                color: $grey50;
              }
            }

            .button {
              @include button-small;
              color: $indigo;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .empty-state {
      padding: 78px 50px 120px;
    }

    .checkout-container {
      @include flexible(column);
      @include sub-heading-large;
      gap: 16px;
      padding: 20px 16px 16px;
      border-top: 1px solid $grey10;
      color: $grey50;

      .text-container {
        @include flexible(row, space-between);
        text-transform: uppercase;
      }
    }
  }
}