@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.image-grid-container {
  @include size(100%);
  max-height: 774px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  display: grid;
  column-gap: 4px;
  row-gap: 4px;

  img {
    object-fit: cover;
  }

  .image-container {
    position: relative;
    overflow: hidden;

    &.clickable {
      cursor: pointer;
    }

    .more-button {
      display: none;

      &.active {
        display: block;
        position: absolute;
        @include flexible(row, center, center);
        background: getColor($black, 0.6);
        cursor: pointer;
        bottom: 0;
        font-size: 2rem;
        color: $white;
        width: 100%;
        height: 100%;
        right: 0;
      }
    }
  }

  &.two-horizontal {
    grid-template-rows: 1fr 1fr;
  }

  &.two-vertical {
    grid-template-columns: 1fr 1fr;
  }

  &.two-square {
    grid-template-columns: 1fr 1fr;
  }


  &.three-vertical {
    grid-auto-flow: column;
    grid-template-areas: "A B"
                         "A B"
                         "A C"
                         "A C";

    div:first-child {
      grid-area: A;
    }

    div:nth-child(2) {
      grid-area: B;
    }

    div:nth-child(3) {
      grid-area: C;
    }
  }

  &.three-horizontal {
    grid-auto-flow: column;
    grid-template-areas: "A A"
                         "B C";

    div:first-child {
      grid-area: A;
    }

    div:nth-child(2) {
      grid-area: B;
    }

    div:nth-child(3) {
      grid-area: C;
    }
  }

  &.four-squares {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  &.four-horizontal {
    grid-auto-flow: column;
    grid-template-areas: "A A A"
                         "B C D";

    div:first-child {
      grid-area: A;
    }

    div:nth-child(2) {
      grid-area: B;
    }

    div:nth-child(3) {
      grid-area: C;
    }

    div:nth-child(4) {
      grid-area: D;
    }
  }

  &.four-vertical {
    grid-auto-flow: row;
    grid-template-areas: "A B"
                         "A C"
                         "A D";

    div:first-child {
      grid-area: A;
    }

    div:nth-child(2) {
      grid-area: B;
    }

    div:nth-child(3) {
      grid-area: C;
    }

    div:nth-child(4) {
      grid-area: D;
    }
  }

  &.five-squares {
    grid-auto-flow: column;
    grid-template-areas: "A A A B B B"
                         "C C D D E E";

    div:first-child {
      grid-area: A;
    }

    div:nth-child(2) {
      grid-area: B;
    }

    div:nth-child(3) {
      grid-area: C;
    }

    div:nth-child(4) {
      grid-area: D;
    }

    div:nth-child(5) {
      grid-area: E;
    }
  }

  &.five-horizontal {
    grid-auto-flow: column;
    grid-template-areas: "A C"
                         "A C"
                         "A D"
                         "B D"
                         "B E"
                         "B E";

    div:first-child {
      grid-area: A;
    }

    div:nth-child(2) {
      grid-area: B;
    }

    div:nth-child(3) {
      grid-area: C;
    }

    div:nth-child(4) {
      grid-area: D;
    }

    div:nth-child(5) {
      grid-area: E;
    }
  }
}