@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixin";

.follow-button-container {
  @include flexible(row, center, center);
  width: 100%;

  .follow-button {
    @include flexible(row, center, center);
    @extend %body-small;
    color: $themeBlue;
    cursor: pointer;
    border-radius: 2px;
    padding: 2px 0 2px 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}