@import "src/styles/colors";
@import "src/styles/mixin";

.image-with-loader-container {
  @include flexible(row, center, center);
  position: relative;
  height: 100%;
  width: 100%;

  .ant-skeleton-with-avatar {
    @include size(100%);

    .ant-skeleton-header {
      padding-right: 0;

      .ant-skeleton-avatar {
        @include size(100%);
        border-radius: 4px;
      }
    }
  }
}