@import "src/styles/mixin";
@import "src/styles/colors";
@import "src/styles/typography";

.mention-dropdown-container {
  position: relative;
  width: 100%;

  .auto-complete-container {
    position: relative;
    width: 100%;

    .mention-auto-complete-dropdown {
      max-height: 259px;
      border: 1px solid $grey10;
      top: 0 !important;

      .rc-virtual-list-scrollbar {
        display: block !important;
      }

      .ant-select-item-option-active {
        background: $grey5;
      }

      .ant-select-item {
        height: unset;
      }

      .user-container {
        display: flex;
        gap: 12px;
        cursor: pointer;

        .text-container {
          @include flexible(column);

          .full-name {
            @include sub-heading-large;
            color: $darkGrey;
          }

          .username {
            @include body-small;
            color: $grey50;
          }

          .shop-name {
            @include DM-Sans-italic-font;
            @include medium;
            color: $grey50;
          }
        }
      }
    }
  }
}