.vacation-mode-message {
  width: fit-content;

  .ant-notification-notice-message {
    padding: 0;
  }

  .ant-notification-notice-close {
    display: none;
  }
}