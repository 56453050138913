@import "src/styles/mixin";
@import "src/styles/typography";

.google-sign-in-container {
  @include size($max, $fit);
  margin-bottom: 16px;
  border-radius: 32px;

  .google-sign-in {
    @include size($max, 48px);
    @include flexible(row, center, center);
    border-radius: 32px !important;
    box-shadow: none !important;
    border: 1px solid $grey15 !important;
    font-family: "DM-Sans-Medium", serif !important;
    font-size: 16px !important;
    line-height: 1.25rem !important;
    color: $darkGrey !important;

    &:hover:enabled {
      background-color: $grey5 !important;

      > div {
        background-color: $grey5 !important;
      }
    }

    > button > span {
      color: $darkGrey !important;
    }

    > div {
      @include flexible(row, center, center);
      margin-right: 0 !important;
      padding: 8px !important;
    }
  }

}

