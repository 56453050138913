@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.product-feed-container {
  background-color: $grey5;
  border-radius: 4px;
  border: $grey10 1px solid;
  margin: 0 16px;
  position: relative;

  > .circle-button {
    position: absolute;
    z-index: 2;
    right: 12px;
    top: 12px;
  }

  .image-container {
    position: relative;

    .product-image {
      height: 217px;

      img {
        border-radius: 4px 4px 0 0;
        object-fit: cover;
      }
    }

    .tag {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 1;
    }

    .price-container {
      @include flexible(row, flex-start, center);
      position: absolute;
      bottom: 16px;
      left: 16px;
      border: $grey10 1px solid;
      background-color: white;
      width: fit-content;
      border-radius: 60px;
      gap: 4px;
      padding: 4px 12px;

      .tag-image {
        @include size(18px);
      }

      .price {
        @include button-medium-large;
        color: $darkGrey;
      }

      .dot {
        @include size(3px);
        margin: 0 2px;
        background: $darkGrey;
        border-radius: 100%;
      }

      .original-price {
        @include body-small;
        color: $grey50;
        text-decoration: line-through;
      }
    }
  }

  .product-title {
    @include flexible(row, space-between, center);
    gap: 4px;
    padding: 16px;
    @include button-medium-large;
    color: $darkGrey;

    .buy-button {
      min-width: 152px;
    }
  }

}