@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.image-uploader-button {
  @include size($fit);
  position: relative;

  .file-input {
    @include size(0.1px);
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

  }

  label {
    @include size(40px);
  }
}