@import "src/styles/colors";
@import "src/styles/mixin";

.menu-buttons {
  @include flexible(row, center, center);
  flex: 1;
  height: 100%;
}


@media only screen and (max-width: $mobile) {
  .menu-buttons {
    justify-content: space-between;
  }
}