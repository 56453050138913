@import "src/styles/mixin";
@import "src/styles/colors";
@import "src/styles/typography";

.feed-bubbles-carousel-skeleton{
  width: 100%;
  padding: 16px 12px;
  border-radius: 4px;

  .items-container {
    @include flexible(row, center, center);
    gap: 20px;
  }

  .bubble-skeleton-container {
    @include size(fit-content);
    max-width: $maxWidth;
    background: white;

    .ant-skeleton-content {
      .ant-skeleton-title {
        margin-bottom: 0;
        height: 30px;
      }
    }


    .title {
      width: 100px;
    }

  }
}