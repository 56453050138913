@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.logged-out-menu {
  @include flexible(row, center, center);
  @include size($max, $menuSizeHeader);
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid getColor($black, 0.08);

  .inner-content {
    @include flexible(row, space-between, center);
    @include size($max);
    max-width: 1120px;

    .tedooo-logo {
      @include size(40px);
      @include flexible(row, center, center);
      border-radius: 4px;
      cursor: pointer;

      svg {
        @include size(28px)
      }
    }
    .logged-out-menu-buttons-container {
      flex: 1;

      .logged-out-menu-buttons {
        @include flexible(row)
      }
    }

    .buttons {
      @include flexible(row);

      .button {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .logged-out-menu {
    .inner-content {
      padding: 0 8px;


      .tedooo-logo {
        @include size(60px);

        border-radius: 4px;
        cursor: pointer;

        svg {
          @include size(40px)
        }
      }

      .buttons {
        @include flexible(row);

        .button {
          white-space: nowrap;
          padding: 0 8px;

          .text {
            margin: 4px;
          }

          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}