@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  src: local('Poppins-Regular'), url(../fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Light';
  font-weight: 300;
  src: local('Poppins-Light'), url(../fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'DM Sans Italic';
  font-weight: normal;
  src: local('DMSans-Italic'), url(../fonts/DMSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  font-weight: 500;
  src: local('Poppins-Medium'), url(../fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-weight: 600;
  src: local('Poppins-SemiBold'), url(../fonts/Poppins-SemiBold.ttf) format('truetype');
}


@font-face {
  font-family: 'DM-Sans-Regular';
  font-weight: 400;
  src: local('DM-Sans-Regular'), url(../fonts/DM-Sans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DM-Sans-Medium';
  font-weight: 500;
  src: local('DM-Sans-Medium'), url(../fonts/DM-Sans-Medium.ttf) format('truetype');
}
