@import "src/styles/mixin";
@import "src/styles/typography";

.shop-item {
  position: relative;
  cursor: pointer;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 1px 22px rgba(40, 47, 45, 0.08);
  background-color: white;

  .shop-inner-container {
    @include flexible(column);
    height: 100%;
  }

  .premium-tag-container {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1;
  }

  .images-container {
    display: flex;
    gap: 1px;
    padding: 4px;


    .image-wrapper:first-child {
      img {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    .image-wrapper:nth-child(3) {
      img {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .shop-image {
      height: 97px;
      flex: 1;

      img {
        object-fit: cover;
      }

      &.empty {
        img {
          @include size(80px);
          object-fit: contain;
        }
      }
    }
  }

  .avatar {
    @include size(40px);
    margin-top: -30px;
    margin-left: 8px;
    margin-bottom: 4px;
    border-radius: 50%;
    border: 2px white solid;
    background: white;

    img {
      border-radius: 50%;
    }
  }

  .carousel-text-container {
    @include ellipsis;
    @include flexible(column);
    padding: 0 12px 12px;
    flex: 1;

    .header {
      display: flex;
      justify-content: space-between;
    }

    .details {
      @include flexible(row, flex-start, center);
      @include ellipsis;
      @include body-small;
      color: $grey50;
      margin-bottom: 12px;
      flex: 1;

      .dot {
        @include size(2px);
        margin: 0 4px;
        background: $grey50;
        border-radius: 100%;
      }

      .shop-category {
        @extend %text-ellipsis;
      }

      .shop-username:before {
        content: "@";
      }
    }

    .shop-name {
      @extend %text-ellipsis;
      @include poppins-semi-bold;
      color: $darkGrey;
      margin-bottom: 4px;
    }
  }
}


@media only screen and (max-width: $mobile) {
  .shop-item {
    .image-best-shop {
      padding: 0 4px;
      height: 140px;

      img {
        object-fit: cover;
      }
    }

    .carousel-text-container {
      .shop-country {
        font-size: 0.7rem;
      }

      .shop-name {
        font-size: 0.8rem;
      }
    }
  }
}