@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.no-result-container {
  @include size($max);
  @include flexible(column, flex-start, center);
  background-color: white;
  padding-top: 104px;
  gap: 32px;

  .no-result-logo {
    margin-top: 10px;
    @include size(181px, 104px);
  }

  .text-container {
    @include flexible(column, center, center);
    gap: 8px;

    .no-result-text {
      @include heading-3;
      text-align: center;
    }

    .sub-text {
      padding: 0 8px;
      @include body-regular;
      text-align: center;
      color: $grey50;
    }
  }
}
