@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.menu-drawer-header {
  @include flexible(row, flex-start, center);
  padding-bottom: 12px;
  border-bottom: 1px solid $grey5;

  .text-container {
    @include flexible(column);
    margin-left: 8px;

    .title {
      @extend %button-small;
      margin-top: 4px;
    }

    .text {
      @extend %caption;
      color: $grey50;
      margin-top: 2px;
    }
  }
}