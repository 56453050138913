@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.footer-container {
  @include label-small;
  @include flexible(row, flex-start, center);
  color: $grey50;
  margin-top: 24px;

  a {
    color: $grey50;
  }

  .dot {
    @include size(2px);
    margin: 0 4px;
    background: $grey50;
  }

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
