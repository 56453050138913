@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.password-message-update {
  padding: 16px;

  .ant-notification-notice-content .ant-notification-notice-with-icon {
    display: flex;

    .ant-notification-notice-description {
      display: none;
      margin: 0;

    }

    .ant-notification-notice-btn {
      @extend %body-regular;
      margin-top: 0;
      margin-left: 8px;
      cursor: pointer;

      > span {
        font-weight: 500;
        color: $themeBlue;
      }
    }

    .ant-notification-notice-message {
      @extend %body-regular;
      display: flex;
      width: fit-content;
      margin-left: 36px;
      padding: 0;

      .text {
        white-space: nowrap;
        flex: 1;
      }
    }
  }
}