@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixin";

.option-item-group {
  border-bottom: 1px solid $grey5;

}

.option-item {
  @include flexible(row, flex-start, center);
  padding: 8px 12px;
  margin: 4px 0;
  cursor: pointer;

  .prefix-icon {
    object-fit: contain;
    height: 22px;
    width: 24px;
    margin-right: 8px;
  }

  .tag {
    margin-left: 8px;
  }

  .text {
    @extend %body-small;
    color: $darkGrey;
  }

  &:hover {
    background: $indigo5;

    > .text {
      color: $indigo;
    }
  }

  &.sign-out-container {
    @include flexible(row, flex-start, center);

    svg {
      @include size(16px);

      path {
        fill: $danger;
      }
    }

    &:hover {
      background: $danger5;

    }

    .text {
      margin-left: 8px;
      color: $danger;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .option-item {
    padding: 12px 0;

    .text {
      @extend %body-small;
    }

    &:hover {
      background: $indigo5;

      .text {
        color: $indigo;
      }
    }

    &.sign-out-container {
      @include flexible(row, flex-start, center);

      svg {
        @include size(16px);

        path {
          fill: $danger;
        }
      }

      &:hover {
        background: $danger5;

      }

      .text {
        margin-left: 8px;
        color: $danger;
      }
    }
  }

}