@import "src/styles/mixin";

.multi-items-carousel-container {
  @include flexible(column, center);
  width: 100%;
  padding: 20px;
  max-width: $maxWidth;
  background-color: white;

  .slick-track {
    margin-left: 0;
  }

  :not(:has(.responsive-arrows)) {
    .slick-arrow {
      height: 50px;
      width: 50px;
      z-index: 2;
      top: calc(50% - 25px);

      .button-carousel {
        @include button(50px);
        pointer-events: visible;
        background-color: transparent;
        border: none;

        &.prev-button-carousel {
          transform: rotate(180deg);
        }

        &:disabled {
          opacity: 0;
          pointer-events: none;
        }
      }

      &.slick-prev {
        left: -25px;
      }

      &.slick-next {
        right: -25px;
      }

      &:before {
        font-size: unset;
      }
    }
  }

  .responsive-arrows {
    .slick-arrow {
      @include size(fit-content);
      top: 50%;
      z-index: 1;

      &.slick-disabled {
        display: none !important;
      }

      &.slick-next {
        right: 8px;
      }

      &.slick-prev {
        transform: rotate(180deg) translateY(50%);
        left: 8px;
        z-index: 1;
      }

      &:before {
        display: none;
      }

      .slider-button {
        padding: 10px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .multi-items-carousel-container {
    padding: 8px 0 12px 0;
  }
}