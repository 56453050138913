@import "src/styles/mixin";

.section-titles-container {
  @include flexible(column);
  margin-bottom: 16px;

  .title {
    @include flexible(row, flex-start, center);
    padding: 2px 4px;
    border-radius: 4px;
    gap: 4px;
    width: fit-content;

    svg {
      @include size(20px);
    }

    &.clickable:hover {
      cursor: pointer;
      background: $grey5;
    }
  }
}