@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.sign-footer {
  @include flexible(row, center, center);
  width: 100%;
  padding-bottom: 32px;

  .footer-option {
    color: $grey65;
    @extend %body-small;
    margin-right: 24px;

    &.clickable {
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .sign-footer {
    display: none;
  }
}