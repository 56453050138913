@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixin";

.menu-container {
  @include flexible(row, center, center);
  background: $white;
  height: $menuSizeHeader;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid getColor($black, 0.08);
  overflow: hidden;

  .menu-content {
    @include flexible(row, center, center);
    max-width: 1120px;
    width: 100%;
    height: 100%;

    .basket-button-container {
      position: relative;

      .unread-container {
        position: absolute;
        top: -4px;
        right: -4px;
      }
    }

    .menu-search {
      @include flexible(row, space-between, center);
      flex: 1;

      .avatar-container {
        display: none;
      }

      .tedooo-logo {
        @include size(40px);
        @include flexible(row, center, center);
        border-radius: 4px;
        cursor: pointer;

        svg {
          @include size(28px)
        }
      }

      .menu-icon {
        @include size(26px);
        display: none;
      }

      .notifications-button {
        @include size(32px);
        position: relative;
        display: none;

        .unread-container {
          position: absolute;
          top: -4px;
          right: -4px;
        }

        svg {
          @include size(100%);
        }
      }

      .basket-button-container {
        display: none;
      }

      .discover-title {
        @include heading-5;
        color: $darkGrey;
        display: none;
      }
    }

    .profile-section {
      @include flexible(row, center, center);
      height: 100%;
      gap: 12px;

      .notifications-button {
        position: relative;

        .unread-container {
          position: absolute;
          top: -4px;
          right: -4px;
        }
      }

      .create-post-button {
        padding: 10px;
        border-radius: 50%;
      }

      .menu-drop-button {
        @include size($fit, 40px);
        padding: 4px 4px 4px 12px;
        border-radius: 32px;
        border: 1px solid $grey10;

        &:hover {
          background: $grey5;
          box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
        }

        svg {
          @include size(18px);
        }

        .avatar-container {
          margin-left: 8px;
        }
      }
    }
  }

  &.dark-theme {
    background: getColor(#484E4C99, 1);
    box-shadow: 0 7px 7px 0 rgba(109, 109, 109, 0.04);

    .search-container-menu {
      .ant-input-affix-wrapper {
        border: 1px solid $grey50;
        background: $grey85;

        .ant-input-prefix {
          svg path {
            fill: $white;
          }
        }

        input {
          color: $white;


          &::placeholder {
            color: $white;
          }
        }
      }
    }

    .menu-item {
      svg path {
        fill: $grey50;
      }

      .line {
        background: none;
      }

      &.selected {
        svg path {
          fill: $white;
        }

        .line {
          background: $white;
        }
      }

      &:hover {
        background: $grey65;
      }
    }

    .circle-button {
      background: $grey85;
      border-color: $grey65;

      svg path {
        fill: $white;
      }

      &:hover {
        background: $grey65;
      }
    }

    .profile-section {
      .menu-drop-button {
        svg path {
          fill: $white;
        }

        &:hover {
          background: $grey65;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .menu-container {
    .menu-content {
      flex-direction: column;
      overflow-x: hidden;
      padding: 0 8px 0 4px;


      .menu-search {
        width: 100%;
        margin-top: 8px;

        .notifications-button {
          display: initial;
        }

        .basket-button-container {
          display: initial;

          .clickable-image {
            @include size(32px);

            svg {
              @include size(24px);

              path {
                fill: $grey65;
              }
            }
          }
        }

        .menu-icon {
          display: initial;
        }

        .search-container-menu {
          flex: 1;
          overflow-x: hidden;
          padding-right: 0;
          margin-right: 4px;

        }

        .notifications-button, .basket-button-container {
          margin: 0 4px 0 0;
        }

        .tedooo-logo {
          display: none;
        }

        .discover-title {
          display: unset;
        }
      }
    }
  }
}
