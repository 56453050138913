@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.interest-item-button {
  border: 1px solid $grey5;
  color: $darkGrey;
  padding: 12px 16px;
  box-shadow: 0 1px 11px rgba(40, 47, 45, 0.08);
  border-radius: 37px;
  cursor: pointer;

  .interests-button-text {
    @include button-medium-large;
  }

  &:hover {
    background-color: $grey5;
    border: 1px solid $grey10;
  }

  &.isSelected {
    border: 1px solid $main25;
    background-color: $main15;
    color: $mainDark;
    padding: 12px 16px;
    box-shadow: 0 1px 11px rgba(40, 47, 45, 0.08);
    border-radius: 37px;
    cursor: pointer;

    .interests-button-text {
      @include button-medium-large;
    }
  }
}