@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.chat-message-error {
  width: fit-content;
  background: $danger10;
  border: 1px solid $danger25;
  border-radius: 4px;
  padding: 12px 8px;

  .ant-notification-notice-message {
    @include flexible(row, flex-start, center);
    white-space: nowrap;
  }
  .ant-notification-notice-close{
    right: 12px;
    top: 15px;
  }

  .text {
    @include button-small;
    color: $darkGray;
  }

  .ant-notification-notice-message {
    margin-left: 36px;
    padding-right: 32px;
  }

  .circle-icon {
    @include flexible(row, center, center);
    @include size(22px);
    border-radius: 50%;
    background: $danger;

    .x-icon {
      @include size(12px);

      path {
        fill: white;
      }
    }
  }
}