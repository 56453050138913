@import "src/styles/mixin";
@import "src/styles/typography";

.post-skeleton {
  @include size(100%, 548px);
  background: white;
  border-radius: 4px;
  padding: 20px 0;
  margin-bottom: 16px;

  .title-skeleton {
    padding: 0 24px;

    .ant-skeleton-content {

      .ant-skeleton-paragraph {
        width: 117px;
        margin-top: 0;
      }
    }
  }

  .image-skeleton {
    width: 100%;
    height: 346px;

    .ant-skeleton-header {
      padding: 0;

      .ant-skeleton-avatar {
        @include size(100%)
      }
    }
  }

  .text-skeleton {
    .ant-skeleton-paragraph {
      padding: 0 24px;

      li {
        height: 8px;
      }
    }
  }
}