@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.profile-card-container {
  @include size($max, $fit);
  background: $white;
  border-radius: 4px;

  .header {
    @include flexible(column, flex-start, flex-start);
    margin-bottom: 4px;

    .cover-photo-container {
      @include size($max, 88px);

      .cover-photo {
        @include size(100%);

        img, .overlay {
          border-radius: 4px 4px 0 0;
          object-fit: cover;
        }
      }
    }

    .avatar-container {
      margin-left: 16px;
      margin-top: -27px;

      .avatar {
        border: 4px solid $white;
        cursor: pointer;
      }
    }
  }

  .text-container {
    @include flexible(column, flex-start, flex-start);
    padding: 0 16px;
    margin-bottom: 16px;

    .clickable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .shop-name {
      @include heading-5;
      @include ellipsisLines(1, 1.5rem);
      color: $darkGrey;
    }

    .username {
      @include body-regular;
      @include ellipsisLines(1, 1.5rem);
      text-align: center;
      color: $grey50;
      margin-bottom: 4px;

      &:before {
        content: "@";
      }
    }

    .followers-container {
      display: flex;
      gap: 16px;

      .follow-text-container {
        @include button-medium-large;
        color: $darkGrey;
      }

      > span {
        display: flex;

        .follow {
          @include body-regular;
          color: $grey50;

          &:before {
            content: "\00a0 ";
          }
        }
      }
    }
  }

  .button-container {
    padding: 0 16px 12px;
  }

  .profile-card-footer {
    @include flexible(row, center, center);
    border-top: 1px solid $grey10;

    .button {
      @include size(100%);
      padding-bottom: 14px;
      padding-top: 16px;
    }
  }
}