@import "src/styles/mixin";
@import "src/styles/colors";
@import "src/styles/typography";

.ant-notification-notice.primary-success-message {
  &.toggle-favorite-message {
    background: $main10;
    border: 1px solid $main25;
  }
}
