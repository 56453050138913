@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.page-container {
  > .content {

    .feed-fallback-container {
      padding-top: 24px;
      display: flex;
      flex-direction: row;
      gap: 24px;

      .left-section {
        width: 100%;
        flex: 2;

        .create-post-and-bubbles-container {
          @include flexible(column, flex-start, flex-start);
          gap: 24px;
          margin-bottom: 24px;

          .create-post-skeleton {

            .ant-skeleton-header {
              padding-right: 0;

              .ant-skeleton-avatar-square {
                border-radius: 4px;
                height: 68px;
                width: 100%;
              }
            }
          }

          .bubbles-skeleton {

            .ant-skeleton-header {
              padding-right: 0;

              .ant-skeleton-avatar-square {
                border-radius: 4px;
                height: 68px;
                width: 100%;
              }
            }
          }
        }
      }

      .right-section {
        flex: 1;

        .right-skeleton {
          @include size(100%);
          border-radius: 8px;
          padding: 0;

          .ant-skeleton-header {
            padding-right: 0;

            .ant-skeleton-avatar {
              @include size(100%);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .page-container {
    > .content {
      .feed-fallback-container {
        .right-section {
          display: none;
        }
      }
    }
  }
}