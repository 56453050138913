@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.profile-created-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  width: 480px;
  height: 622px;
  padding: 40px 56px 48px 56px;
  @include flexible(column, flex-start, center);

  .text-container {
    @include flexible(column);
    width: 100%;

    .title {
      @include heading-3;
      color: $darkGrey;
      margin-bottom: 4px;
      text-align: center;
    }

    .description {
      color: $grey50;
      @include body-small;
      text-align: center;
      width: 35ch;
      margin-bottom: 48px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    position: relative;

    .animation-container {
      top:-100px;
      position: absolute;
    }

    .image-wrapper {
        @include size(208px);
        margin-bottom: 48px;
        border-radius: 50%;
      overflow: hidden;
    }

    .user-name {
      @include heading-3;
      color: $darkGrey;
      margin-bottom: 4px;
      &:before{
        content: "@";
      }
    }

    .submit-button {
      margin-bottom: 16px;
      z-index: 1;
    }
  }

}
@media only screen and (max-width: 900px) {
  .profile-created-container {
    .text-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content-container {
      flex: 1;
    }
  }

}

