@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.profile-side-bar {
  @include flexible(column);
  @include size($max, $fit);

  .complete-shop-box-container {
    margin-top: 24px;
  }
}