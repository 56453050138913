@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.video-container {
  @include flexible(column, center, center);
  @include size($max);
  position: relative;

  .mojo-skeleton {
    @include size(100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    .user-details {
      display: none;
    }

    .image-skeleton {
      display: none;
    }
  }

  video {
    z-index: 1;
    object-fit: contain;
  }

  &::before {
    @include size(100%);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    backdrop-filter: blur(60px);
  }

  .overlay {
    @include size($max);
    @include flexible(row, center, center);
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 999;

    svg {
      @include size(80px);
      animation: opacityAnimation 0.2s ease-in-out;
    }
  }
}