@mixin semi-bold {
  font-weight: 600;
}

@mixin medium {
  font-weight: 500;
}

@mixin regular {
  font-weight: 400;
}

@mixin light {
  font-weight: 300;
}

@mixin poppins-font {
  font-family: "Poppins", serif;

}

@mixin DM-Sans-font {
  font-family: "DM Sans", serif !important;

}
@mixin DM-Sans-italic-font {
  font-family: "DM Sans Italic", serif !important;

}

@mixin poppins-regular {
  font-family: "Poppins", serif;
}
@mixin poppins-light {
  font-family: "Poppins-Light", serif;
}
@mixin poppins-semi-bold {
  font-family: "Poppins-SemiBold", serif;
}

@mixin DM-Sans-regular {
  font-family: "DM-Sans-Regular", serif;
}

@mixin DM-Sans-medium {
  font-family: "DM-Sans-Medium", serif;
}


@mixin giant-heading {
  @include poppins-semi-bold;
  font-size: 2.75rem;
  line-height: 3.313rem;
}

@mixin heading-2 {
  @include poppins-semi-bold;
  @include semi-bold;
  font-size: 1.75rem;
  line-height: 2rem;
}

@mixin heading-3 {
  @include poppins-semi-bold;
  font-size: 1.5rem;
  line-height: 2rem;
}

@mixin heading-4 {
  @include poppins-semi-bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin heading-5 {
  @include poppins-semi-bold;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin button-small {
  @include DM-Sans-medium;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@mixin sub-heading {
  @include poppins-semi-bold;
  font-size: 0.75rem;
  line-height: 1rem;
}

@mixin sub-heading-large {
  @include poppins-semi-bold;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@mixin button-medium-large {
  @include DM-Sans-medium;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin body-regular {
  @include DM-Sans-regular;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin body-small {
  @include DM-Sans-regular;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@mixin caption {
  @include DM-Sans-regular;
  font-size: 0.75rem;
  line-height: 1rem;
}

@mixin label-small {
  @include DM-Sans-medium;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

%giant-heading {
  @include giant-heading;
}

%heading-2 {
  @include giant-heading;
}

%heading-2 {
  @include heading-2;
}

%heading-3 {
  @include heading-3;
}

%heading-4 {
  @include heading-4;
}

%heading-5 {
  @include heading-5;
}

%sub-heading {
  @include sub-heading;
}

%sub-heading-large {
  @include sub-heading-large;
}

%button-medium-large {
  @include button-medium-large;
}

%button-small {
  @include button-small;

}

%body-regular {
  @include body-regular;
}

%body-small {
  @include body-small;
}

%caption {
  @include caption;
}

.giant-heading {
  @extend %giant-heading;
}

.heading-2 {
  @extend %heading-2;
}

.heading-3 {
  @extend %heading-3;
}

.heading-4 {
  @extend %heading-4;
}

.heading-5 {
  @extend %heading-5;
}

.sub-heading {
  @extend %sub-heading;
}

.sub-heading-large {
  @extend %sub-heading-large;
}

.button-medium-large {
  @extend %button-medium-large;
}

.button-small {
  @extend %button-small;
}

.body-regular {
  @extend %body-regular;
}

.body-small {
  @extend %body-small;
}

.caption {
  @extend %caption;
}

%text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

%font-xxs {
  font-size: 0.75rem;
}


%body-regular {
  font-size: 1rem;
}
