@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.comment-reaction-container {
  @include flexible(column);
  @extend %body-small;
  line-height: 0.7rem;
  color: getColor($black, 0.6);
  width: 100%;

  .decorative-image {
    @include flexible(row, center, center);

    .image {
      margin-right: 4px;
    }

    .text {
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $blue;
      }
    }
  }

  .reply-text-container {
    @include flexible(row, flex-start, center);
    gap: 8px;

    .date {
      @include body-small;
      color: $grey35;
    }

    .line {
      @include size(0, 12px);
      border-right: $grey35 1px solid;
    }

    .text {
      @include button-small;
      cursor: pointer;
      color: $grey65;

      &.not-clickable {
        pointer-events: none;
      }

      &:hover {
        text-decoration: underline;
      }

      &.liked {
        color: $main;
      }
    }
  }
}