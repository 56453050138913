@import "src/styles/mixin";
@import "src/styles/colors";
@import "src/styles/typography";

.shop-of-the-day-container {
  .image-container {
    @include size(100%, 253px);
    background-size: cover;
    border-radius: 4px 4px 0 0;
  }

  .details-container {
    @include flexible(row, space-between, center);
    padding: 12px 16px;
    width: 100%;
    border-radius: 0 0 4px 4px;

    .section-titles-container {
      overflow: hidden;

      margin-bottom: 0;
    }
  }
}