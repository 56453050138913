@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.sign-in-form {
  margin-top: 24px;
  width: 100%;

  .button {
    border-radius: 32px;
    width: 100%;
  }
}

