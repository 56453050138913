@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.menu-search .search-container-menu {
  .ant-select-selection-search, input, .ant-select-selection-search, .base-input {
    @include size(100%)
  }

  input {
    border-radius: 20px;
  }
}