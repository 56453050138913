@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.join-groups-container {
  background-color: white;
  @include flexible(column, flex-start, center);
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  width: 480px;
  height: 656px;
  border-radius: 4px;

  .text-container {
    padding: 40px 56px 0 56px;
    @include flexible(column);
    width: 100%;

    .title {
      @include heading-3;
      color: $darkGrey;
      margin-bottom: 4px;
    }

    .description {
      color: $grey50;
      @include body-small;
      margin-bottom: 24px;
    }

  }

  .content-container {
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: column;
    padding: 0 56px;
    gap: 16px;
    overflow: auto;
  }


  .buttons-container {
    padding: 40px 56px 40px 56px;
    width: 100%;
    box-shadow: 0 -28px 10px white;

    .continue-button-interests {
      margin-bottom: 16px;
    }
  }

}
@media only screen and (max-width: 900px) {
  .join-groups-container {
    .text-container {
      padding: 0;
    }

    .content-container {
      padding: 0;
    }

    .buttons-container {
      padding: 0;
    }
  }
}
