@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.profile-picture-container {
  background-color: white;
  width: 480px;
  height: 596px;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  padding: 40px 56px;
  @include flexible(column, flex-start, center);

  .text-content {
    @include flexible(column);
    margin-bottom: 48px;
    width: 100%;

    .title {
      @include heading-3;
      color: $darkGrey;
      margin-bottom: 4px;
    }

    .secondary-title {
      color: $grey50;
      @include body-small;
    }
  }

  .profile-picture {
    margin-bottom: 48px;
    position: relative;

    .badge {
      @include flexible(row, center, center);
      @include size(48px);
      background-color: $indigo;
      border-radius: 50%;

      position: absolute;
      bottom: 8px;
      right: 4px;

      &:hover {
        background: $indigoDark;

        > svg path {
          fill: $main5;
        }
      }

      svg {
        @include size(22px);

        path {
          fill: white
        }
      }
    }
  }

    .continue-button-profile {
      margin-bottom: 16px;
      width: 100%;
    }

    .skip-for-now-button-profile {
      width: 100%;
    }

}

@media only screen and (max-width: 900px) {
  .profile-picture-container {
    .profile-picture {
      flex: 1;
    }
  }

}


