@import "src/styles/mixin";
@import "src/styles/colors";
@import "src/styles/typography";
.unread-container {
  @include flexible(row, center, center);
  height: 18px;
  background-color: $main;
  border-radius: 9px;
  color: white;
  text-align: center;
  min-width: 18px;

  span {
    @include label-small;
    padding: 4px;
  }
}
