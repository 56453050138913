@import "src/styles/fonts.scss";
@import "src/styles/typography";
@import "tedooo-web-design-system/tedooo-design-system.css";
//
//iframe#webpack-dev-server-client-overlay {
//  display: none !important;
//}

body {
  padding: 0;
  font-weight: 400;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.spinnerSignInP {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

input[type="checkbox"] {
  width: 13px;
  height: 13px;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 1px;
  -webkit-appearance: none;
}

input[type="checkbox"]:checked {
  appearance: auto;
  background-color: #2196f3;
  -moz-appearance: checkbox;
  -ms-progress-appearance: unset;
  -webkit-appearance: checkbox;

  color: white;

}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f4f4f4;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 10px;

}

::-webkit-scrollbar-thumb:hover {}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
}


.qrDownload {
  width: 100px;
  margin-top: 10px;
  height: 100px;
  align-self: center;
}

input {
  font-size: unset;
  ;
}

#root,
html {
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
}

#root {
  -webkit-overflow-scrolling: touch;
}

.imgCircleTedooo {
  border-radius: 100%;
  padding: 20px;
  background-color: #3cbf95;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 30vw;

}

.react-dropdown-select-dropdown {
  width: 100% !important;
  max-height: 200px !important;
}

.react-dropdown-select-dropdown::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: unset;
}


.css-wmw4vi-ReactDropdownSelect {
  background-color: white !important;
}

.inputBusiness {
  width: 100%;
}

.dropBoxGeneral {
  min-height: 30px;
  height: fit-content;
  width: 100%;
}

::placeholder {
  color: #626262;
  opacity: 0.7;
}

.sliderAnimation___300FY {
  transition: transform .3s !important;
}

.MainContainer {
  background-color: rgba(214, 214, 214, 0.32);
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.marker {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

html {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}


@media only screen and (max-width: 900px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}