@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.user-details-container {
  @include flexible(row);
  padding: 12px 12px 16px;
  background: $white;

  .images-container {
    @include flexible(row, flex-start, flex-end);

    .shop-image {
      @include size(44px);

      img {
        border-radius: 4px;
      }
    }

    .avatar-container {
      .avatar {
        border: 2px solid white;
      }

      margin-left: -18px;
    }
  }

  .dot {
    @include size(2px);
    border-radius: 100%;
  }

  .text-header {
    @include flexible(row, flex-start, center);

    .premium-tag-container {
      margin-left: 8px;
    }
  }

  .text-container {
    @extend %body-small;
    @include flexible(column);
    flex: 1;
    margin-left: 12px;
    margin-right: 12px;
    color: getColor($black, 0.6);
    overflow: hidden;

    .title-container {
      @include flexible(row, flex-start, center);
      @include heading-5;
      text-transform: capitalize;
      width: 100%;
      gap: 4px;
      flex: 1;

      .title-text {
        @include ellipsisLines(1, 1.5rem);
        color: $darkGrey;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .dot {
        background: $darkGrey;
      }

      .follow-button-container {
        width: unset;
        margin-right: 4px;

        .follow-button {
          @include button-medium-large;
          padding: 0;
        }
      }
    }

    .sub-text {
      @include flexible(row, flex-start, center);
      gap: 4px;
      color: $grey50;

      .dot {
        background-color: $grey50;
      }
    }

    > .text {
      @extend %text-ellipsis;
      width: 100%;
    }
  }

  .user-details-button {
    @include flexible(column, flex-start, flex-end);

    .options-menu {
      border-radius: 4px;

      &.ant-dropdown-trigger {
        @include size(24px);
        transform: rotate(90deg);
      }

      &:hover {
        background: $grey5;
      }
    }
  }
}


@media only screen and (max-width: $mobile) {
  .user-details-container {
    padding: 12px;
  }
}