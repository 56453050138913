@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.birthday-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  width: 480px;
  height: 445px;
  padding: 40px 56px;
  position: relative;
  flex:1;
  @include flexible(column, flex-start, center);

  .text-container {
    @include flexible(column);
    width: 100%;

    .title {
      @include heading-3;
      color: $darkGrey;
      margin-bottom: 4px;
    }

    .cake-image {
      @include size(101px);
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .secondary-title {
      color: $grey50;
      @include body-small;
      margin-bottom: 32px;
    }
  }

  .date-picker-wrapper {
    width: 100%;
    margin-bottom: 16px;
  }

  .age-section-text {
    @include flexible(column);
    @include body-small;
    color: $grey65;
    margin-bottom: 48px;
    flex: 1;

    .first-row-age-text {
      text-align: center;

      .years-span {
        color: $tedooo;
        font-weight: 700;
      }
    }

    .second-row-age-text {
      text-align: center;
    }
  }

  .continue-button-birthday {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 900px) {
  .birthday-container {
    .text-container {
      .cake-image {
        @include size(101px);
        position: absolute;
        right: 10px;
        top: -32px;
      }
    }
  }
}