@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.create-post-container {
  @include flexible(row, flex-start, center);
  width: 100%;
  flex-shrink: 0;
  padding: 12px;
  background: $white;
  margin-bottom: 24px;
  box-shadow: 0 1px 7px rgba(40, 47, 45, 0.07);
  border-radius: 4px;
  gap: 12px;

  .avatar-container {
    cursor: pointer;
  }

  .start-post-button {
    @extend %body-small;
    border-radius: 32px;
    border: 1px solid $grey10;
    padding: 0 12px;
    text-align: start;
    height: 44px;
    background: transparent;
    flex: 1;
    justify-content: flex-start;
    color: $grey50;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      border: 1px solid $grey25;
      background: $grey5;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .create-post-container {
    border-radius: 0;
    margin-bottom: 8px;

    .catalog-button {
      display: none;
    }
  }
}
