$messageColor: #dcf8c6;
$lightBorder: #d6d6d6c9;
$borderColor: #0000004C;
$white: white;
$black: black;
$betterBlack: #212121;
$tedooo: #3cbf95;
$themeBlue: #3174E3;
$blue: #3c7fef;
$facebookBlue: #3b5898;
$darkGray: #333333;
$amber: #915907;
$grey: #777777;
$midGrey: #4f4f4f;
$orangeTheme: #FB842E;
$lightGrey: #c7c7c7;
$offWhite: #F9F9F9;
$background-primary: rgba(241, 241, 241, 0.66);
$gray95: #f2f2f2;
$gray10: #E9EAEA;
$primary-gray: rgba(0, 0, 0, 0.6);
$antiFlashWhite: #f1f1f169;


//Updates
$darkGrey: #191E1C;
$grey85: #484E4C;
$grey65: #737877;
$grey50: #979495;
$grey35: #B4B6B6;
$grey25: #C9CBCA;
$grey15: #DFE0DF;
$grey10: #E9EAEA;
$grey5: #F4F5F5;
$grey2: #FBFBFB;
$indigoDark: #02488F;
$indigo: #0A66C2;
$indigo65: #609CD7;
$indigo25: #C2D9F0;
$indigo15: #DAE8F6;
$indigo10: #E6F0F9;
$indigo5: #F3F7FC;
$indigo85: #2F7DCB;

$mainDark: #09918D;
$main: #0BB5B0;
$main85: #30C0BC;
$main65: #60CFCC;
$main50: #85DAD8;
$main35: #AAE5E3;
$main25: #C2EDEB;
$main15: #DAF4F3;
$main10: #E7F8F7;
$main5: #F3FBFB;

$orchid: #AF2BBF;
$purple: #6F4BD6;
$purple10: #F1EDFB;
$purple15: #E9E4F9;
$purple85: #8566DC;
$success: #258750;
$success25: #C8E1D3;
$success10: #E9F3ED;
$darkDanger: #B61400;
$danger: #E11900;
$danger25: #F8C5BF;
$danger10: #FCE8E5;
$danger5: #FEF4F2;
$warning: #FFC043;
$warning5: #FFFCF6;
$darkWarning: #7D5E21;
$warning25: #FFEFD0;
$warning10: #FFF9EC;
$warning15: #FFE5B2;
