@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.reaction-container {
  @include flexible(row, flex-start, center);
  @extend %body-small;
  gap: 16px;
  color: $grey65;

  .reaction {
    @include flexible(row, flex-start, center);
    gap: 4px;
    cursor: pointer;

    .number {
      @include button-small;
      color: $darkGrey;
      white-space: pre-wrap;
    }

    &:hover {
      text-decoration: underline;
    }
  }

}