@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.avatar-container {
  @include size($fit);
  @include flexible(column, center, center);
  position: relative;
  background: $grey5;
  border-radius: 50%;

  .avatar {
    @include size($max);
    object-fit: cover;
    border-radius: 50%;
  }

  .icon-container {
    position: absolute;
    display: flex;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}