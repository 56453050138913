@import "./colors";

$normalTextSize: 1rem;
$textSize: 1.2rem;
$titleSize: 2rem;
$max: 100%;
$wrongTheme: red;
$fit: fit-content;
$mobile: 900px;
$menuSizeHeader: 58px;
$menuSizeFooter: 52px;
$filterSizeWeb: 200px;
$maxWidth: 1120px;
$sideMenuSize: 340px;

@function addBorder($width, $borderColor:$borderColor) {
  @return $width solid $borderColor;
}

@function getColor($color, $opacity) {
  @return rgba($color, $opacity);
}

@mixin flexible($dir:row,$organize:unset,$alignOrganize:unset,$wrap:unset) {
  display: flex;
  flex-direction: $dir;
  justify-content: $organize;
  flex-wrap: $wrap;
  align-items: $alignOrganize;
}

@mixin container() {
  width: 100vw;
  height: 100vh;
}

@mixin text($size,$color:$betterBlack,$weight:unset) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}
@mixin ellipsisLines($lines, $lineHeight) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  line-height: $lineHeight;
  max-height: #{$lineHeight * $lines};


}

@mixin absPos($top:unset,$right:unset,$bottom:unset,$left:unset) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
}

@mixin size($width,$height:$width) {
  width: $width;
  height: $height;
}

@mixin image($width,$height:$width,$objectFit:contain) {
  width: $width;
  height: $height;
  object-fit: $objectFit;
}

@mixin min($width,$height:unset) {
  min-width: $width;
  min-height: $height;
}
@mixin smallScrollBarStyle {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey5;
    border-radius: 10px;

  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: white;
  }
}

@mixin button($width,$height:$width) {
  width: $width;
  transition: all 50ms;
  height: $height;
  cursor: pointer;
  object-fit: contain;
  &:hover {
    opacity: 0.9;
  }
}

