@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.interests-empty-state-container {
  @include flexible(column, center, center);
  flex: 1;
  gap: 16px;

  .image-wrapper {
    @include size(70px)
  }

  .text-container {
    @include flexible(column, center, center);
    padding: 0;

    .title {
      @include heading-5;
      font-size: 16px;
      color: $darkGray;
    }

    .description {
      @include DM-Sans-regular;
      font-size: 14px;
      color: $grey50;
    }
  }
}