@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.onboarding-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .form-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow: hidden;
  }

  .background-image {
    @include size($max);
    background: $main5;
    top: 0;
    z-index: -1;
    position: fixed;
  }

  .mobile-header {
    display: none;
    width: 100%;
  }

}

@media only screen and (max-width: 900px) {
  .onboarding-container {
    .step-container {
      width: 100%;
      height: 100%;
      box-shadow: none;
      padding: 0 24px 24px 24px;
    }

    height: 100%;
    display: flex;
    flex-direction: column;

    .form-container {
      width: 100%;
    }

    .background-image {
      display: none;
    }

    .mobile-header {
      display: flex;
      padding: 12px 24px 24px 24px;

      .full-logo-icon {
        @include size(24px)
      }

      .tedooo-text {
        @include size(74px, 16px);
        margin-left: 4px;
      }
    }
  }
}