@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

#feed-container {
  gap: 32px;
  height: 100%;

  .info-container {
    margin-bottom: 24px;
  }

  .preview {
    padding-left: 0;
    @include flexible(column);
  }
}