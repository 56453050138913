@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.page-container {
  height: 100%;
  flex-direction: column;
  overflow-y: hidden;

  > .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: $background-primary;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    height: calc(100% - #{$menuSizeHeader});

    > .page {
      @include flexible(row);
      width: 100%;
      max-width: $maxWidth;
      padding: 8px 0;

      &.without-max-width {
        max-width: unset;
      }

      &.feed-layout {
        .preview {
          flex: 9;
          max-width: 736px;
        }

        .list {
          flex: 4;
          max-width: 352px;
          padding: 0 8px;
        }
      }

      .side-bar {
        margin-top: 24px;
        padding: 0;
        flex: 8;
        height: fit-content;
        position: sticky;
        top: 24px;
        max-width: 459px;
      }

      .side-menu {
        position: sticky;
        top: 0;
        flex: 1;
        margin-top: 16px;
      }

      .preview {
        display: flex;
        flex: 3;
        margin: 16px 0;
        height: 100%;
        padding-left: 24px;
        padding-right: 1px;
        padding-bottom: 1px;

        &:only-child {
          padding: 0;
        }

        &.column {
          flex-direction: column;
        }

        &.full-width {
          width: 100%;
          padding-left: 1px;
        }
      }

      .inner-preview {
        flex: 11;
        margin-top: 24px;
        height: $fit;
        padding-left: 24px;
        overflow: hidden;
        padding-bottom: 8px;

        &:only-child {
          padding-left: 0;
        }
      }

      .section {
        @include flexible(column);
        @include size($max, $fit);
        box-shadow: 0 1px 7px rgba(40, 47, 45, 0.07);
        border-radius: 4px;
        background: $white;
        margin-top: 24px;

        > .button {
          border-top: 1px solid $gray10;
          height: 55px;
          width: 100%;
        }

        &:first-child {
          margin-top: 1px;
        }

        > .header {
          padding: 24px 24px 0;

          > .title {
            @extend %sub-heading;
            color: $grey65;
            text-transform: uppercase;
          }
        }

        > .title {
          @extend %sub-heading;
          padding: 24px 24px 0;
          color: $grey65;
          text-transform: uppercase;
        }

        .section-content {
          padding: 16px 24px 24px;

          &.with-button {
            padding-bottom: 0;
          }
        }

        .information-buttons-container {
          @include flexible(column);
          width: 100%;
          border-top: 1px solid $lightBorder;
          padding: 8px 0;
          background: $white;
          border-radius: 0 0 8px 8px;
          overflow-x: hidden;

        }
      }

      .list {
        flex: 1;
        margin-top: 16px;
        position: sticky;
        position: -webkit-sticky;
        height: fit-content;
        top: 24px;
        overflow-x: hidden;
        overflow-y: initial;
      }
    }
  }

}

@media only screen and (max-width: $mobile) {
  .page-container {
    > .content:has(.without-top-menu) {
      height: calc(100% - #{$menuSizeFooter});
    }

    > .content {
      padding: 0;
      height: calc(calc(100% - #{$menuSizeHeader}) - #{$menuSizeFooter});

      > .page {

        .side-menu {
          display: none;
        }

        .side-bar {
          margin-top: 8px;
          position: relative;
          top: unset;
          max-width: unset;
        }

        .preview {
          padding-left: 0;
          padding-right: 1px;
          width: 100%;
          margin: 0;
          padding-bottom: 1px;
        }

        .inner-preview {
          margin-top: 8px;
          padding-left: 1px;
          padding-right: 1px;
          padding-bottom: 0;
        }

        .section {
          margin-top: 8px;
          border-radius: 0;
          box-shadow: none;

          > .button {
            height: 55px;
            width: 100%;
          }

          &:first-child {
            margin-top: 0;
          }

          > .header {
            padding: 12px 12px 0;
          }

          > .title {
            padding: 16px 12px 0;
          }

          .section-content {
            padding: 24px 12px 16px;

          }
        }

        .list {
          display: none;
        }
      }
    }
  }
}