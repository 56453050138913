@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.menu-item {
  @include flexible(column, center, center);
  width: 108px;
  margin-right: 24px;
  height: 100%;
  padding-top: 16px;
  cursor: pointer;
  position: relative;

  .line {
    width: 100%;
    height: 2px;
    transition: all .2s ease-in-out;
    background: $white;
  }

  > svg {
    @include size(24px);
    margin-bottom: 16px;
    fill: $grey85;
  }

  .unread-container {
    position: absolute;
    right: 34px;
    top: 9px;
    border: 1px solid white;
  }

  &.selected {
    .line {
      background: $grey85;
    }

    .text {
      color: $darkGrey;
    }

    svg path {
      fill: $darkGrey
    }
  }

  &:hover {
    background: $grey5;
  }
}

@media only screen and (max-width: $mobile) {
  .menu-item {
    @include flexible(column-reverse, flex-end, center);
    margin-right: 8px;
    padding-bottom: 8px;
    flex: 1;
    padding-top: 0;

    .unread-container {
      right: 24px;
      top: 4px;
    }

    &:last-child {
      margin-right: 0;
    }

    > svg {
      @include size(22px);
      margin-top: 8px;
      margin-bottom: 0;
    }

    .text {
      display: none;
    }
  }
}