@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.image-wrapper {
  position: relative;
  display: flex;
  @include size(100%);

  img {
    @include size(100%);
  }

  video {
    @include size(100%);
  }

  .overlay {
    @include size(100%);
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background: getColor($darkGrey, 0.1);
  }
}