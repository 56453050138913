@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.post-container {
  &.repost-container {
    @include size($max, $max);
    background: $white;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    border: 1px solid $grey10;

    .image-grid-container {
      .image-container {
        cursor: unset;
      }
    }

    .expanded-text-container {
      @extend %body-small;
      color: $darkGrey;
      padding: 0 12px;
      margin-bottom: 24px;

      &.no-text {
        margin: 0;
      }

      .clickable-text {
        color: $grey;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .background-index-container {
      @include size(100%);
      @include flexible(row, center, center);
      position: relative;
      cursor: auto;
      padding: 0 60px;
      aspect-ratio: 1.5;

      .image-wrapper {
        position: absolute;
        height: unset;

        img {
          max-height: 498px;
          aspect-ratio: 1.5;
        }
      }

      .text {
        @include heading-2;
        text-align: center;
        word-break: break-word;
        z-index: 1;
      }
    }

    .images-grid-container {
      width: 100%;
      height: 400px;
    }

    > .expanded-text-container {
      padding-bottom: 12px;
      @extend %body-small;

      .text {
        color: $black;
      }
    }

    .post-details-inner-container {
      @include flexible(column);
      overflow: auto;
      flex: 1;
      width: 100%;
      padding: 0;

      &.no-images {
        .reaction-container {
          margin-top: 0;
        }
      }

      .post-image-container {
        width: 100%;

        .product-feed-container {
          margin: 0;

          .product-image {
            height: 413px;
          }
        }
      }

      > .expanded-text-container .text {
        white-space: pre-wrap;
        overflow-wrap: anywhere;
        width: 100%;
      }
    }

    .post-footer-container {
      .reaction-container {
        margin-top: 16px;
        padding: 0 16px 12px;
      }

      .post-actions-menu-container {
        margin-bottom: 16px;
      }
    }

    .comments-container {
      margin-bottom: 16px;

      &.no-comments {
        margin-bottom: 0;
      }
    }

    .input-wrapper {
      padding: 0 8px;

      .decorative-input-container {
        margin: 0;
      }
    }

    .empty-state {
      justify-content: center;
      flex: 1;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .post-container {
    margin-bottom: 8px;
    border-radius: 0;

    .images-grid-container {
      height: 300px;
    }

    .background-index-container {
      padding: 0;
    }
  }
}