@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.search-container-menu {
  padding: 0 10px;
  display: flex;

  .auto-complete-container {
    cursor: unset;
    height: unset;

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: $grey10;
    }

    .ant-input-affix-wrapper {
      @extend %body-small;
      height: 40px;
      border-radius: 32px;
      background: $grey5;
      border: none;

      input {
        @extend %body-small;
        color: $darkGrey;
        background: transparent;

        &::placeholder {
          color: $grey50

        }
      }

    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      outline: none;
      box-shadow: none;
    }

    .ant-select-selection-search {
      .ant-input-prefix svg {
        @include size(16px);

        path {
          fill: $grey50;
        }
      }
    }
  }
}

.search-container-menu:has(.search-container) {
  width: 100%;

  .auto-complete-container {
    width: 100%;
    padding: 0;
  }
}

.auto-complete-dropdown {
  width: 300px;
  padding: 8px;

  .ant-select-selector {
    min-height: unset;
  }

  &.recent {
    .ant-select-item-option-content {
      color: purple;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .search-container-menu {
    width: 100%;

    .auto-complete-container {
      width: 100%;

      .ant-input-affix-wrapper {
        height: 34px;
      }
    }
  }
}
