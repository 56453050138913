@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.group-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 15px rgba(40, 47, 45, 0.1);
  padding: 24px 16px;
  border: 1px solid $gray10;
  cursor: pointer;
  position: relative;

  .avatar-text-container {
    display: flex;
    align-items: center;
    gap: 12px;

    .text-content {
      display: flex;
      flex-direction: column;

      .title{
        @include heading-5;
        color: $darkGray;
      }
      .members {
        @include button-small;
        color: $grey85;
      }
    }
  }

  .checkbox-container {
    height: 100%;
  }

  &:hover{
    border: 1px solid $grey25;
  }

  &.selected {
    background-color: $indigo5;
    border: 1px solid $indigo65;
  }
}