@import "src/styles/colors";
@import "src/styles/mixin";

.footer-menu {
  @include size(100%, $menuSizeFooter);
  border-top: 1px solid $grey10;

  &.is-non-sign-in-user {
    @include size(100%,60px);
  }

  &.dark-theme {
    background: getColor(#484E4C99, 1);
    box-shadow: 0 7px 7px 0 rgba(109, 109, 109, 0.04);

    .search-container-menu {
      .ant-input-affix-wrapper {
        border: 1px solid $grey50;
        background: $grey85;

        .ant-input-prefix {
          svg path {
            fill: $white;
          }
        }

        input {
          color: $white;


          &::placeholder {
            color: $white;
          }
        }
      }
    }

    .menu-item {

      svg path {
        fill: $grey50;
      }

      .line {
        background: none;
      }

      &.selected {
        svg path {
          fill: $white;
        }

        .line {
          background: $white;
        }
      }

      &:hover {
        background: $grey65;
      }
    }

    .circle-button {
      background: $grey85;
      border-color: $grey65;

      svg path {
        fill: $white;
      }

      &:hover {
        background: $grey65;
      }
    }

    .profile-section {
      .menu-drop-button {
        svg path {
          fill: $white;
        }

        &:hover {
          background: $grey65;
        }
      }
    }
  }
}
