@import "src/styles/mixin";
@import "src/styles/typography";
@import "src/styles/colors";

.ant-drawer {
  &.shop-more-drawer {
    .ant-drawer-close {
      display: none;
    }

    .ant-drawer-header {
      display: none;
    }

    .ant-drawer-content-wrapper {

      .ant-drawer-content {
        border-radius: 18px 18px 0 0;

        .ant-drawer-body {
          padding: 0;

          .field {
            @include flexible(row, flex-start, center);
            gap: 8px;
            padding-left: 16px;
            padding-top: 14px;
            padding-bottom: 14px;
            margin-top: 24px;
            margin-bottom: 29px;

            svg {
              height: 16px;
              width: 16px;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}