@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.feed-vision-section {
  @include flexible(column);
  background: $white;
  margin-top: 24px;

  .text-container {
    @include flexible(column, flex-start, center);
    padding: 16px 16px 20px;
    text-align: center;

    .title {
      @include heading-5;
      color: $darkGrey;
      margin-bottom: 8px;
    }

    .description {
      @include body-small;
      color: $grey50;
      margin-bottom: 16px;
    }
  }
}
