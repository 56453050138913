@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.comment-wrapper {
  display: flex;
  width: 100%;
  padding: 6px 0;
  gap: 8px;

  .dropdown-container {
    display: none;
  }

  &:hover {
    .dropdown-container {
      display: block;
    }
  }

  .avatar-container {
    cursor: pointer;
  }

  .comment-inner-wrapper {
    @include flexible(column);
    gap: 8px;
    flex: 1;

    .comment-with-dropdown {
      gap: 12px;
      @include flexible(row, flex-start, center);

      .comment-inner-container {
        @include flexible(column);
        position: relative;
        overflow: unset;
        gap: 8px;
        background: $gray95;
        padding: 8px 8px 16px;
        width: fit-content;
        border-radius: 0 8px 8px 8px;

        .tags-container {
          display: flex;
          gap: 8px;

          .tag {
            border: 1px $indigo15 solid;
          }
        }

        .header {
          @include flexible(row, flex-start, center);

          .ant-dropdown-trigger {
            @include flexible(row, flex-start, center);
            @include size(20px);
            margin-left: 4px;

            svg {
              @include size(14px);
              fill: getColor($black, 0.6);
            }
          }

          .full-name {
            @include button-medium-large;
            @include ellipsis;
            color: $darkGrey;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .username {
            @include body-regular;
            color: $grey65;
          }

          .dot {
            @include size(2px);
            margin: 0 4px;
            background: $grey65;
            border-radius: 100%;
          }


          > .image-wrapper {
            @include size(18px);
          }
        }

        .expanded-text-container {
          @extend %body-small;
          padding: 0;
          margin: 0;

          .text {
            @include body-small;
            color: $grey85;
          }
        }

        .comment-likes-container {
          @include flexible(row, center, center);
          @include body-small;
          @include size(43px, 20px);
          position: absolute;
          bottom: 0;
          right: 8px;
          transform: translateY(50%);
          gap: 4px;
          border-radius: 16px;
          color: $grey65;
          border: 1px solid $grey10;
          background: white;
          cursor: pointer;
          transition: transform 0.1s linear;

          svg {
            @include size(12px);

            path {
              fill: $main;
            }
          }

          &:hover {
            transform: translateY(50%) scale(1.1);
          }
        }
      }

      .dropdown-container {
        position: relative;

        .button {
          padding: 8px;
          border-color: $indigo15;
        }
      }
    }

    .comment-image-container {
      @include size(124px);
      border-radius: 8px;

      img {
        border-radius: 8px;
      }
    }
  }

  &.reply {
    .comment-inner-wrapper .comment-inner-container {
      padding: 8px;
      max-height: 160px;
    }
  }

  &.deleted .comment-inner-container .expanded-text-container .text {
    @extend %body-small;
    font-style: italic;
    font-weight: 500;
    color: $grey;
  }

}

.options-menu-content {
  &.comment-drop-menu {
    width: 200px;

    .options-menu-items .item {
      svg {
        @include size(14px)
      }
    }
  }
}