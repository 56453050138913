@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixin";

.premium-tag-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  @include size(77px, 33px);
  background-color: getColor(#191E1C, 0.8);
  border-radius: 4px;

  .icon {
    @include size(17px);
  }

  .text {
    @include poppins-regular;
    font-size: 0.875rem;
    color: white;
  }

  &.medium {
    @include size(59px, 27px);
    padding: 0 8px;
    gap: 4px;

    .icon {
      @include size(15px);
    }
  }

  &.small {
    @include size(52px, 22px);
    padding: 0 6px;
    gap: 4px;

    .icon {
      @include size(15px);
    }
  }
}