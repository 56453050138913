@import "src/styles/mixin";
@import "src/styles/typography";

.comment-wrapper-container {
  @include flexible(column);

  .decorative-input-container {
    .avatar-container {
      margin-right: 8px;
    }
  }

  .replies-container {
    padding-left: 40px;

    .reply-text {
      @include flexible(row, flex-start, center);
      @include button-small;
      width: fit-content;
      gap: 8px;
      color: $grey50;
      cursor: pointer;
      margin-top: 6px;
      margin-bottom: 12px;

      .line {
        @include size(22px, 0);
        border: 1px solid $grey25;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}