@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";

.profile-menu-content {
  width: 210px;
  border-radius: 12px;

  &.full-width {
    width: 309px;

    .draft-wrapper {
      padding: 12px;

      .complete-shop-box-container {
        padding: 16px;
      }
    }
  }

  .profile-drop-menu {
    @include flexible(column);
    padding: 4px;

    .header {
      @include flexible(row, flex-start, center);
      padding: 6px 12px;
      cursor: pointer;
      border-bottom: 1px solid $grey5;

      &:hover {
        background: $indigo5;

        .text-container {
          .text {
            color: $indigo;
          }
        }
      }

      .text-container {
        @include flexible(column, center);
        margin-left: 12px;

        .title {
          @extend %body-small;
          color: $darkGrey;
        }

        .text {
          @extend %caption;
          color: $grey50;
          margin-top: 2px;
        }
      }
    }
  }
}