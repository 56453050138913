@import "src/styles/colors";
@import "src/styles/mixin";
@import "src/styles/typography";


.tedooo-pro-box-container {
  @include flexible(column, flex-start, flex-start);
  border: 1px solid $grey10;
  border-radius: 4px;
  background-image: url("../../../photos/premium/premium-background.png");
  background-size: cover;
  padding: 16px 24px 24px 24px;
  margin-top: 24px;
  box-shadow: 0 1px 7px 0 rgba(40, 47, 45, 0.07);

  .benefits-row {
    @include flexible(row, flex-start, center);
    gap: 12px;
    margin-bottom: 16px;

    .benefit {
      flex: 1;
    }

    svg {
      path {
        fill: $main;
      }

      object-fit: cover;
      width: 20px;
      height: 20px;
    }
  }

  .tedooo-pro-logo-image {
    @include size(164px, 40px);
    margin-bottom: 16px;
  }

  .title {
    @include heading-5;
    color: $darkGrey;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .description {
    @include DM-Sans-regular;
    font-size: 14px;
    color: $darkGrey;
    margin-bottom: 30px;
    line-height: 20px;

    .inner-description {
      @include DM-Sans-medium;
      font-size: 14px;
      color: $darkGrey;
      font-weight: 700;
    }
  }

  .benefits-row {
    .benefit {
      @include DM-Sans-regular;
      font-size: 14px;
      color: $darkGrey;
    }
  }
}